<template>
  <div>
    <div>
      <b-navbar type="dark" variant="dark" toggleable="lg">
        <b-navbar-toggle target="nav_dropdown_collapse" />
        <b-navbar-brand href="#">
          Rosie Online
        </b-navbar-brand>
        <b-collapse
          is-nav
          id="nav_dropdown_collapse"
          v-if="isAuthenticated && !hasNoResources"
        >
          <b-navbar-nav>
            
            <b-nav-item-dropdown
              text="Applikasjon"
              right
              v-bind:toggle-class="isLoggerPage ? 'active' : ''"
            >
              <b-dropdown-item
                style="font-size: .85rem;"
                href="/weir"
                v-if="hasWeirResource"
                :active="isWeirPage"
                >Overløp</b-dropdown-item
              >
              <b-dropdown-item
                style="font-size: .85rem;"
                href="/level"
                v-if="hasLevelResource"
                :active="isLevelPage"
                >Nivå</b-dropdown-item
              >
              <b-dropdown-item
                style="font-size: .85rem;"
                href="/flow"
                v-if="hasFlowResource"
                :active="isFlowPage"
                >Vannføring</b-dropdown-item
              >
              <b-dropdown-item
                style="font-size: .85rem;"
                href="/rain"
                v-if="hasRainResource"
                :active="isRainPage"
                >Nedbør</b-dropdown-item
              >
            </b-nav-item-dropdown>
            <b-navbar-nav class="ml-left" v-if="isWeirPage">
              <b-nav-item href="/weir/report" v-bind:active="isReportPage"
                >Rapport</b-nav-item
              >
            </b-navbar-nav>
            <b-navbar-nav class="ml-left" v-if="!isWeirPage">
              <b-nav-item
                :href="getDownloadUrl"
                v-bind:active="isDownloadPage"
                >{{ getDownloadText }}</b-nav-item
              >
            </b-navbar-nav>
          </b-navbar-nav>
        </b-collapse>
        <b-navbar-nav id="nav_dropdown_login" class="ml-auto">
          <b-nav-item v-if="isAuthenticated && isWeirPage && !hasNoResources"
            ><a
              href="#"
              class="text-light"
              @click="showSettingsModal = !showSettingsModal"
              ><font-awesome-icon icon="cog" size="1x"/></a
          ></b-nav-item>
          <b-nav-text v-if="isAuthenticated"
            >{{ userName }},
          </b-nav-text>
          <b-nav-item
            v-if="isAuthenticated"
            href="/"
            @click="handleLogoutClick"
            active
          >
            Logg ut <font-awesome-icon icon="sign-out-alt"
          /></b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    </div>
    <div id="app">
      <router-view />
      <notifications group="app" position="bottom right" />
    </div>
    <b-modal
      v-model="showSettingsModal"
      @ok="handleSettingsOk"
      @show="resetSettingsModal"
      @hidden="resetSettingsModal"
      title="Innstillinger"
      cancel-title="Avbryt"
    >
      <b-overlay :show="phosphorQuantityStatusIsLoading" rounded="sm">
        <form ref="form" @submit.stop.prevent="handleSettingsSubmit">
          <b-form-group
            :state="phosphorQuantityInputState"
            label="mgP per liter"
            label-for="phosphorQuantityInput"
            invalid-feedback="'mgP per liter' is required and should be >= 0"
          >
            <b-form-input
              id="phosphorQuantityInput"
              v-model="phosphorQuantityform"
              :state="phosphorQuantityInputState"
              type="number"
              size="sm"
              lang="no"
              required
            />
          </b-form-group>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { mapGetters, mapActions } from 'vuex'
import { LOGOUT, UPDATE_PHOSPHOR_QUANTITY } from './store/action-types'
import { ERROR, LOADING } from './store/actions/backend.js'
import './assets/css/main.scss'
import { LEVEL, FLOW, WEIR, RAIN, REPORT } from './utils/logger_consts'

export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'authStatus',
      'refreshStatus',
      'userName',
      'phosphorQuantityStatus',
      'phosphorQuantity',
      'hasResource'
    ]),

    phosphorQuantityInputState: function () {
      return (
        this.phosphorQuantityform != null &&
        this.phosphorQuantityform !== '' &&
        parseInt(this.phosphorQuantityform) >= 0
      )
    },

    phosphorQuantityStatusIsLoading: function () {
      return this.phosphorQuantityStatus === LOADING
    },

    isWeirPage: function () {
      return (
        (this.$route.name === 'main' ||
          this.$route.name === WEIR ||
          this.$route.name === REPORT ||
          this.$route.name === WEIR + 'Details') &&
        this.hasWeirResource
      )
    },

    isLevelPage: function () {
      return (
        this.$route.name === LEVEL ||
        this.$route.name === LEVEL + 'Details' ||
        this.$route.name === LEVEL + 'Download' ||
        (this.$route.name === 'main' &&
          !this.hasWeirResource &&
          this.hasLevelResource)
      )
    },

    isFlowPage: function () {
      return (
        this.$route.name === FLOW ||
        this.$route.name === FLOW + 'Details' ||
        this.$route.name === FLOW + 'Download' ||
        (this.$route.name === 'main' &&
          !this.hasWeirResource &&
          !this.hasLevelResource &&
          this.hasFlowResource)
      )
    },

    isRainPage: function () {
      return (
        this.$route.name === RAIN ||
        this.$route.name === RAIN + 'Details' ||
        this.$route.name === RAIN + 'Download' ||
        (this.$route.name === 'main' &&
          !this.hasWeirResource &&
          !this.hasLevelResource &&
          !this.hasFlowResource &&
          this.hasRainResource)
      )
    },

    isLoggerPage: function () {
      return !this.isReportPage && !this.isDownloadPage
    },

    isReportPage: function () {
      return this.$route.name === REPORT
    },

    isDownloadPage: function () {
      return this.$route.path.includes('/download')
    },

    getDownloadUrl: function () {
      if (this.isLevelPage) {
        return '/level/download'
      }

      if (this.isFlowPage) {
        return '/flow/download'
      }

      if (this.isRainPage) {
        return '/rain/download'
      }

      return ''
    },

    getDownloadText: function () {
      return 'Last ned data'
    },

    hasLevelResource: function () {
      return this.hasResource(LEVEL)
    },

    hasWeirResource: function () {
      return this.hasResource(WEIR)
    },

    hasFlowResource: function () {
      return this.hasResource(FLOW)
    },

    hasRainResource: function () {
      return this.hasResource(RAIN)
    },

    hasNoResources: function () {
      return !this.hasLevelResource && !this.hasWeirResource && !this.hasFlowResource && !this.hasRainResource
    }
  },
  methods: {
    ...mapActions({
      logout: LOGOUT,
      updatephosphorQuantity: UPDATE_PHOSPHOR_QUANTITY
    }),

    handleLogoutClick: function () {
      this.logout().then(() => {
        window.localStorage.clear()
        this.$router.push('/login')
      })
    },

    resetSettingsModal: function () {
      this.phosphorQuantityform = this.phosphorQuantity
    },

    handleSettingsOk: function (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSettingsSubmit()
    },

    handleSettingsSubmit: function () {
      if (!this.phosphorQuantityInputState) {
        return
      }

      const payload = { phosphorQuantity: this.phosphorQuantityform }
      this.updatephosphorQuantity(payload).then(() => {
        this.showSettingsModal = false
      })
    }
  },

  watch: {
    refreshStatus: function () {
      if (this.refreshStatus === ERROR) {
        this.$router.push('/login')
      }
    }
  },

  data () {
    return {
      showSettingsModal: false,
      phosphorQuantityform: 0
    }
  }
}
</script>

<style>
#app {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
