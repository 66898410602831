import { UPDATE_PAGE_SETTINGS } from '../action-types'
import { PAGE_SETTINGS_UPDATE } from '../mutation-types'

const state = {
  pagesSettings: []
}

const getters = {
  pagesSettings: state => state.pagesSettings
}

const actions = {
  [UPDATE_PAGE_SETTINGS]: ({ commit }, params) => {
    commit(PAGE_SETTINGS_UPDATE, params)
  }
}

const mutations = {
  [PAGE_SETTINGS_UPDATE]: (state, params) => {
    var pageSettingsIndex = state.pagesSettings.findIndex(
      ps => ps.page === params.page
    )
    if (pageSettingsIndex >= 0) {
      state.pagesSettings.splice(pageSettingsIndex, 1)
    }
    state.pagesSettings.push({
      page: params.page,
      zoom: params.zoom,
      center: params.center,
      selected_active_option: params.selected_active_option,
      filterShown: params.filterShown,
      filterSearchText: params.filterSearchText,
      filterSliderValue: params.filterSliderValue,
      showColumnState: params.showColumnState
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
